import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.disableSpinner()
  }

  openSpinner(e) {
    $('.overlay').addClass('show');
    $('.spanner').addClass('show');
  }

  disableSpinner(e) {
    $('.spanner').removeClass('show');
    $('.overlay').removeClass('show');
  }
}
