document.addEventListener('turbo:load', fileUpload);
document.addEventListener('turbo:render', fileUpload);

function fileUpload() {
  const btnUpload = document.getElementById('button-upload-file');
  if (btnUpload) {
    btnUpload.onclick = function () {
      document.getElementById('file-upload').click();
    };

    // check size and image profile
    document.getElementById('file-upload').onchange = function () {
      const inputFile = document.getElementById('file-upload');
      const fileImg = inputFile.files[0];
      const imageProfile = document.getElementById('image-profile');
      const profileAlert = document.getElementById('profile-alert');
      const maximumSize = 2 * 1024 * 1024; // In MegaBytes

      if (fileImg.size > maximumSize) {
        inputFile.value = '';
        imageProfile.style.backgroundImage = '';
        profileAlert.textContent = 'File is too big !!!';
        profileAlert.style.color = 'red';
      } else {
        imageProfile.style.backgroundImage = `url(${URL.createObjectURL(fileImg)})`;
        profileAlert.textContent = ' ';
      }
    };
  }
}
