document.addEventListener('turbo:load', () => {
  if (document.getElementById('filter-size')) {
    const div_ft = document.getElementById('filter-size');
    const icon_ft = document.getElementById('icon-filter');
    const size_ft = document.querySelectorAll(
      '#filter-size input[type=checkbox]',
    );
    const cencel_ft = document.getElementById('cancel-filter');
    const apply_ft = document.getElementById('apply-filter');

    function clear_filter() {
      size_ft.forEach((cb) => {
        cb.checked = false;
      });
    }

    icon_ft.addEventListener('click', () => {
      div_ft.style.display = 'block';
    });

    // apply filter
    apply_ft.addEventListener('click', () => {
      let createStart = '';
      let createEnd = '';
      let dueStart = '';
      let dueEnd = '';

      if ($('#create-start')) {
        createStart = $('#create-start').val();
        $('#create-start').attr('required', false);
      }
      if ($('#create-end')) {
        createEnd = $('#create-end').val();
        $('#create-end').attr('required', false);
      }
      if ($('#due-start')) {
        dueStart = $('#due-start').val();
        $('#due-start').attr('required', false);
      }
      if ($('#due-end')) {
        dueEnd = $('#due-end').val();
        $('#due-end').attr('required', false);
      }

      // validate
      if (createStart !== '' && createEnd == '') {
        $('#create-end').attr('required', true);
      } else if (createStart == '' && createEnd !== '') {
        $('#create-start').attr('required', true);
      } else if (dueStart !== '' && dueEnd == '') {
        $('#due-end').attr('required', true);
      } else if (dueStart == '' && dueEnd !== '') {
        $('#due-start').attr('required', true);
      } else {
        div_ft.style.display = 'none';
      }
    });

    // cancle
    cencel_ft.addEventListener('click', () => {
      div_ft.style.display = 'none';
      clear_filter();
    });
  }
});
