import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['materialsStocks'];

  initialize() {
    this.materialsInputs = this.cloneInput();
    this.initialSelect2();
  }

  cloneInput() {
    const inputs = document.querySelector('#inputs-materials-stock');
    const tempInputs = document.querySelector('#temp-inputs');
    const materialsInputs = tempInputs.appendChild(inputs.cloneNode(true));
    return materialsInputs;
  }

  initialSelect2() {
    const select = $('select#materials_stock_material_id')[0];

    if (!select.length) {
      return;
    }

    $(select).select2().on('select2:select', this.onSelect2Change);

    document.addEventListener('turbo:before-cache', () => {
      if (!$(select).data('select2')) {
        return;
      }

      $(select).select2('destroy');
    });
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  change(e) {
    const select = e.target;
    const input = select.parentNode.parentNode;
    const data = JSON.parse(select.options[select.selectedIndex].dataset.material);

    input.querySelector('#materials_stock_material_type').value = data.material_type;
    input.querySelector('#materials_stock_color').value = data.color;
    input.querySelector('#materials_stock_size').value = data.size;
    input.querySelector('#materials_stock_balance').value = data.balance;
  }

  remove(e) {
    e.preventDefault();
    e.target.parentNode.parentNode.parentNode.remove();
  }

  addMore(e) {
    e.preventDefault();

    const input = this.materialsInputs.cloneNode(true);
    this.materialsStocksTarget.appendChild(input);

    const select = input.getElementsByTagName('select')[0];
    select.setAttribute('id', `material_id_${new Date().getTime()}`);

    const $select = $(select);
    $select.select2().on('select2:select', this.onSelect2Change);
  }
}
