import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['show', 'unhide'];

  password() {
    if (this.input.type === 'password') {
      this.input.type = 'text';
    } else {
      this.input.type = 'password';
    }
  }

  get value() {
    return this.showTarget;
  }

  get input() {
    return this.unhideTarget;
  }
}
