import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

// Connects to data-controller="pick-item"
export default class extends Controller {
  connect() {
    this.disableSpinner();
    if (document.getElementById('codeContainer')) {
      this.showWarning();
      if (this.getCode().length > 0) {
        this.removePointerNone();
      }
    }
    if (window.location.search) {
      this.setBoxNo();
    }
  }

  showWarning() {
    const warning = document.getElementById('itemWarning');
    if (warning && warning.value) {
      const warn = JSON.parse(warning.value);
      const allCode = this.getCode();
      const index = allCode.map((x) => x.split(':')[0]).indexOf(warn.code);
      allCode.splice(index, 1);
      this.setCode(allCode);

      switch (warn.error) {
        case 'over_than': {
          const msg = 'Qty over than Remaining shipped in this Pick ticket';
          const code = this.genCodeMsg(warn.code);
          const maxValue = warn.max_value ? Math.abs(warn.max_value) : 1;
          const over_remaining = `Over than Remaining : ${maxValue}`;
          this.showModalWarning(msg, code, over_remaining);
          break;
        }

        case 'mismatch': {
          const msg = 'SKU mismatch in this pick ticket';
          const code = this.genCodeMsg(warn.code);
          this.showModalWarning(msg, code);
          break;
        }

        case 'not_found': {
          const msg = `Not found this ${warn.type}.`;
          const code = this.genCodeMsg(warn.code);
          this.showModalWarning(msg, code);
          break;
        }

        case 'other_box': {
          const msg = `This ${warn.type} already scan in other Box.`;
          const code = this.genCodeMsg(warn.code);
          this.showModalWarning(msg, code);
          break;
        }
      }
    }
  }

  genCodeMsg(code) {
    return code.split(':').length > 1 ? code.split(':')[0] : code;
  }

  inputBoxName(e) {
    const value = e.target.value;

    if (!value || Number(value)) {
      pick_item_box_name.classList.remove('is-invalid');
    } else {
      pick_item_box_name.classList.add('is-invalid');
    }
  }

  showModalWarning(msg, code, over_remaining = null) {
    alert_messages.innerHTML = msg;
    alert_barcode.innerHTML = code;
    if (over_remaining) {
      show_over_remaining.classList.remove('d-none');
      show_over_remaining.innerHTML = over_remaining;
    }
    this.myModal($('#modalItemWarning')).show();
    this.clearAllInput();
  }

  getCode() {
    return codeContainer.value.trim() !== ''
      ? codeContainer.value.split(',')
      : [];
  }

  setCode(data) {
    codeContainer.value = data.join(',');
  }

  loadData() {
    const data = this.getCode();
    this.openSpinner();
    const url = `/shippings/${pick_item_shipping_id.value}/pick_items/pick_item_list?code=${data}`;
    get(url, {
      responseKind: 'turbo-steam',
    });
    this.clearAllInput();
  }

  clearAllInput() {
    barcodeInput.value = '';
    skuInput.value = '';
    qtyInput.value = '';
  }

  scanBarcode(e) {
    if (e.keyCode === 13) {
      const allCode = this.getCode();
      const barcode = e.target.value;
      const re = /^\d{1,}-\d{1,}$/;
      if (barcode.trim() && re.test(barcode)) {
        if (allCode.includes(barcode)) {
          const msg = 'The barcode is already in this Box.';
          this.showModalWarning(msg, barcode);
          return;
        }
        allCode.push(barcode);
        if (allCode.length > 0) {
          this.setCode(allCode);
          this.loadData();
        }
      } else {
        const msg = 'Please enter the correct format barcode.';
        this.showModalWarning(msg, barcode);
      }
    }
  }

  scanSku(e) {
    const qtyValue = qtyInput.value.trim();
    const skuValue = skuInput.value.trim();

    if (qtyValue) qtyInput.classList.remove('is-invalid');
    if (skuValue) skuInput.classList.remove('is-invalid');
    if (e.keyCode !== 13) return;

    const allCode = this.getCode();
    if (!qtyValue && !skuValue) {
      qtyInput.classList.add('is-invalid');
      skuInput.classList.add('is-invalid');
    } else if (qtyValue && !skuValue) {
      skuInput.classList.add('is-invalid');
      qtyInput.classList.remove('is-invalid');
    } else if (skuValue && !qtyValue) {
      qtyInput.classList.add('is-invalid');
      skuInput.classList.remove('is-invalid');
    } else if (qtyValue && skuValue) {
      qtyInput.classList.remove('is-invalid');
      skuInput.classList.remove('is-invalid');

      if (skuValue.split('-').length === 3) {
        const index = allCode.map((x) => x.split(':')[0]).indexOf(skuValue);
        if (index > -1) {
          const msg = 'The SKU already scans this.';
          this.showModalWarning(msg, skuValue);
          return;
        }

        const val = `${skuValue}:${qtyValue}`;
        allCode.push(val);
        if (allCode.length > 0) {
          this.setCode(allCode);
          this.loadData();
        }
      } else {
        const msg = 'Please enter the correct format SKU.';
        this.showModalWarning(msg, skuValue);
      }
    }
  }

  unReceive(e) {
    const code = e.target.value;
    const allCode = this.getCode();
    const index = allCode.map((x) => x.split(':')[0]).indexOf(code);
    allCode.splice(index, 1);
    this.setCode(allCode);
    this.loadData();
  }

  updateQty(e) {
    const maxValue = parseInt(e.target.max);
    const input = e.target.id;
    const value = parseInt(e.target.value);
    const allCode = this.getCode();
    const index = allCode.map((c) => c.split(':')[0]).indexOf(input);
    if (value > maxValue) {
      const msg = 'Qty over than Remaining shipped in this Pick ticket';
      const over_remaining = `Over than Remaining : ${value - maxValue}`;
      this.showModalWarning(msg, input, over_remaining);
      document.getElementById(input).value = allCode[index].split(':')[1];
      return;
    }

    allCode[index] = `${input}:${value}`;
    this.setCode(allCode);
    this.loadData();
  }

  removePointerNone() {
    confirm_cancel.classList.remove('pointer-none');
  }

  receivePickItem() {
    const allCode = this.getCode();
    if (allCode.length > 0) {
      this.pickItem(allCode);
    }
  }

  async pickItem(allCode) {
    await this.buildElement(allCode);
    await form_pick_item.submit();
  }

  buildElement(allCode) {
    allCode.forEach((c, i) => {
      const [sku, item, qty] = this.parseCode(c);

      const divElement = this.generateElement('div', [
        { name: 'id', value: `item-${i}` },
      ]);

      const itemElement = this.generateElement('INPUT', [
        { name: 'type', value: 'hidden' },
        {
          name: 'name',
          value: 'pick_item[pick_item_details_attributes[][item_id]]',
        },
        { name: 'value', value: item },
      ]);

      const skuElement = this.generateElement('INPUT', [
        { name: 'type', value: 'hidden' },
        {
          name: 'name',
          value: 'pick_item[pick_item_details_attributes[][sku]]',
        },
        { name: 'value', value: sku },
      ]);

      const qtyElement = this.generateElement('INPUT', [
        { name: 'type', value: 'hidden' },
        {
          name: 'name',
          value: 'pick_item[pick_item_details_attributes[][quantity]]',
        },
        { name: 'value', value: qty },
      ]);

      divElement.appendChild(itemElement);
      divElement.appendChild(skuElement);
      divElement.appendChild(qtyElement);
      pickItemDetailsAttributes.appendChild(divElement);
    });
  }

  parseCode(c) {
    var sku = '';
    var item = '';
    var qty = 1;

    var type = c.split('-').length === 3 ? 'sku' : 'barcode';
    if (type === 'sku') {
      sku = c.split(':')[0];
      qty = parseInt(c.split(':')[1]);
    } else {
      item = c.split('-')[1];
    }

    return [sku, item, qty];
  }

  generateElement(tag, attrList) {
    const divElement = document.createElement(tag);
    attrList.map((attr) => divElement.setAttribute(attr.name, attr.value));
    return divElement;
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  openSpinner() {
    $('.overlay').addClass('show');
    $('.spanner').addClass('show');
  }

  disableSpinner() {
    $('.spanner').removeClass('show');
    $('.overlay').removeClass('show');
  }

  focusInputScanBarcode() {
    barcodeInput.focus();
    this.disableSpinner();
    show_over_remaining.classList.add('d-none');
  }

  toggleSelectAllItemDetail(e) {
    const pickItems = pickItemDetailAttributes.children;
    const checkBoxs = document.querySelectorAll('#check_box_pick_item_details');
    [...checkBoxs].map((item_check) => {
      if (e.target.checked) {
        item_check.checked = true;
      } else {
        item_check.checked = false;
      }
    });
    [...pickItems].map((item) => {
      item.children[4].value = e.target.checked;
    });
  }

  checkBoxSeleced(e) {
    const id = e.target.value;
    this.getPickItemDetail(id).children[4].value = e.target.checked;
  }

  updateQuantity(e) {
    const itemID = e.target.dataset.pickItemDetail;
    const input = parseInt(e.target.value);
    const wasQty = parseInt(e.target.dataset.wasQty);
    const maxValue = parseInt(e.target.max);

    if (input > maxValue) {
      e.target.classList.add('is-invalid');
      const itemInvalid = document.getElementById(`item-invalid-${itemID}`);
      if (itemInvalid) {
        itemInvalid.innerHTML = `Over than Remaining: ${input - maxValue}`;
      }
      e.target.value = wasQty;
    } else {
      e.target.classList.remove('is-invalid');
      this.getPickItemDetail(itemID).children[3].value = e.target.value;
    }
  }

  confirmDelete() {
    alertMessage.innerHTML = `Do you want to delete the item from Box #${pick_item_box_name.value}`;
    pickItemID.value = pick_item_id.value;
    this.myModal($('#confirmDeletePickItemDetail')).show();
  }

  deletePickItemDetails() {
    form_pick_item.submit();
  }

  submitFormPickItem() {
    this.unChecked();
  }

  async unChecked() {
    await [...pickItemDetailAttributes.children].map((item) => {
      item.children[4].value = false;
    });
    await form_pick_item.submit();
  }

  setBoxNo() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const boxNO = urlParams.get('boxNO');
    pick_item_box_name.value = boxNO;
  }

  cancelDelete() {
    this.myModal($('#pickItemDetailModal')).show();
    const url = `/shippings/${shipping_id.value}/pick_items/${pickItemID.value}/edit`;
    get(url, {
      responseKind: 'turbo-steam',
    });
  }

  getPickItemDetail(id) {
    return document.getElementById(`pick_item_detail-${id}`);
  }
}
