function initialSelect2() {
  if ($('select.select2').length === 0) { return; }

  $('.select2').each((i, elem) => {
    const options = {};
    const $elem = $(elem);
    const dropdownParent = $elem.data('dropdown-parent');

    if (dropdownParent) {
      options.dropdownParent = $(dropdownParent);
    }

    $elem
      .select2(options)
      .on('select2:select', (e) => {
        const select = e.currentTarget;
        select.dispatchEvent(new Event('change'));
      });
  });
}

document.addEventListener('turbo:render', initialSelect2);
document.addEventListener('turbo:load', initialSelect2);
document.addEventListener('turbo:frame-load', initialSelect2);
