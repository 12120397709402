document.addEventListener('turbo:submit-start', () => {
  $('.overlay').addClass('show');
  $('.spanner').addClass('show');
});

document.addEventListener('turbo:before-cache', () => {
  destroyDataTable('table#goodsStockSkuListTable');
  destroyDataTable('table#pocItemDetailTable');
  destroyDataTable('table#shippingsItemDetailTable');
  destroyDataTable('table#pickItemDetailTable');
});

document.addEventListener('turbo:before-stream-render', () => {
  destroyDataTable('table#pocItemDetailTable');
});

function destroyDataTable(table) {
  if (!$(table).length) { return; }

  if ($.fn.DataTable.isDataTable(table)) {
    const tableConstant = $(table).DataTable();
    tableConstant.destroy();
  }
}
