// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import '@hotwired/turbo-rails';
import 'bootstrap';
import '../vendor/vanilla-nested';
import 'select2';
import 'datatables.net-bs5';

import '../stylesheets/application';
import '../controllers';

require('../src/filter_size');
require('../src/drop_file');
require('../src/upload_image_profile');
require('../src/sidebar');
require('../src/select2');
require('../src/turbo');

Rails.start();
ActiveStorage.start();
