import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  connect() {
    this.initialDataWarning();
  }

  initialDataWarning() {
    var element = document.getElementById('import-warning');
    var warning = JSON.parse(element.dataset.warning) || {};

    if (Object.keys(warning).length > 0) {
      if (warning.error || (warning.invalid_line.length > 0 || warning.not_found.length > 0)) {
        this.myModal($('#template-error')).show();
      }
    }
  }

  submitForm() {
    document.getElementById('missing-materials-stock-form').submit();
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }
}
