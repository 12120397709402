import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
  static targets = ['state', 'status', 'hiddenState', 'hiddenStatus']

  connect() {
    this.initialSelect2();
    this.initialFilter();
    this.hideMenu();
    this.checkReport()
  }

  checkReport() {
    const reportBlank = document.getElementById('report_blank')
    const isPointerNone = document.getElementById('btn-print-report').classList.contains('pointer-none')

    if (reportBlank) {
      if (!isPointerNone) {
        document.getElementById('btn-print-report')?.classList.add('pointer-none','btn-reprint')
        document.getElementById('btn-print-report')?.classList.remove('btn--primary')
      }
    } else {
      if (isPointerNone) {
        document.getElementById('btn-print-report')?.classList.remove('pointer-none','btn-reprint')
        document.getElementById('btn-print-report')?.classList.add('btn--primary')
      }
    }
  }

  getQcStatus(index) {
    const qc_status = ['qc_pass qc_failed', 'qc_pass', 'qc_failed']
    return qc_status[index]
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  initialSelect2() {
    const select = $('select#polSelect')[0];

    if (!select.length) { return; }
    $(select).select2().on('select2:select', this.onSelect2Change);

    // set style of select2 width from auto to 100%
    const select2Dom = document.getElementsByClassName('select2-container')[0]
    select2Dom.classList.add('select2-w-100-p')
  }

  clearStatusOption(statusList) {
    statusList.innerHTML = ''
  }

  setStatusOption(selectValueIndex) {
    const stateList = this.stateTarget;
    const statusList = this.statusTarget;
    const qcStatus = [];

    if (selectValueIndex == undefined || selectValueIndex == null) {
      selectValueIndex = 0
    }

    this.clearStatusOption(statusList)

    if (stateList.value == 'Q.C.') {
      qcStatus.push('All');
      qcStatus.push('Pass');
      qcStatus.push('Fail');
    } else {
      qcStatus.push('All');
    }

    qcStatus.map((x, i) => {
      const status = new Option(x, i);
      statusList.options.add(status) ;
    })

    statusList.value = selectValueIndex
  }

  selectState() {
    const state = this.stateTarget.value;
    const hiddenState = this.hiddenStateTarget;
    const hiddenStatus = this.hiddenStatusTarget;

    if ( state == 'Q.C.' ) {
      hiddenState.value = state
      this.selectQCType();
    } else {
      hiddenState.value = state
      hiddenStatus.value = state
    }

    this.hideMenu();
    this.setStatusOption();
  }

  hideMenu() {
    const state = this.stateTarget.value;
    const statusSelect = document.getElementById('statusSelectForm')

    if (state == 'Q.C.' || state == '') {
      statusSelect.style.display = 'block'
    } else {
      statusSelect.style.display = 'none'
    }
  }

  selectQCType() {
    const hiddenStatus = this.hiddenStatusTarget;
    const status = this.statusTarget;
    hiddenStatus.value = this.getQcStatus(status.value)
  }

  initialFilter() {
    const state = document.getElementById('state');
    const status = document.getElementById('status');
    const hiddenState = this.hiddenStateTarget;
    const hiddenStatus = this.hiddenStatusTarget;

    if (hiddenState.value == 'qc_pass' || hiddenState.value == 'qc_failed' || hiddenState.value == 'qc_pass qc_failed') {
      state.value = 'Q.C.'
      this.setStatusOption(this.changeQcTextToValue(hiddenState.value))
    } else {
      state.value = hiddenState.value
      this.setStatusOption()
    }
  }

  changeQcTextToValue(qc) {
    if (qc == 'qc_pass qc_failed') return 0
    if (qc == 'qc_pass') return 1
    if (qc == 'qc_failed') return 2
  }

  submitForm() {
    document.getElementById('searchReport').submit();
  }
}
