import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = ['barcode'];

  values = [];

  connect(e) {
    this.pointerNone('add');
    const alert = document.getElementById('alert_packing')
    if (alert) {
      const obj = JSON.parse(alert.value)
      if (obj?.not_found || obj?.blank) {
        this.openModal('Not found this barcode.')
      }else if (obj?.already_scan) {
        this.openModal('The barcode already scan this step.')
      }else if (obj?.pol_complete) {
        this.openModal("This POL already completed, you can't scan this item.")
      }else if (obj?.not_pass_qc) {
        this.openModal("This item not pass QC can not scan this step.")
      }
    }
    document.getElementById('input-packing').value = ''
  }

  focusInputScanBarcode() {
    document.getElementById('input-packing')?.focus();
  }

  removeBarcode() {
    const param = document.getElementById('params_packing')
    if (param?.value) {
      var index = this.values.indexOf(param.value)
      if (index > -1) {
        this.values.splice(index, 1)
      }
    }
  }

  scanBarcode(e) {
    if (e.keyCode === 13) {
      this.removeBarcode()
      const inputPacking = document.getElementById('input-packing')?.value.trim();
      const key = /^\d{1,}-\d{1,}$/;
      if (inputPacking !== '' && key.test(inputPacking)) {
        if (this.values.indexOf(inputPacking) === -1) {
          this.values.push(inputPacking);
          document.getElementById('values').innerHTML = this.values.join(',');
          this.getPacking();
        } else {
          this.openModal('The barcode already scan this step.')
        }
      }else {
        this.openModal('Please enter correct format barcode.')
      }
    }
  }

  getPacking(action) {
    this.barcodeTarget.value = this.values;
    if (action === 'show-summary') {
      if (this.values.length === 0) {
        $('#m-footer').addClass('d-none');
      } else {
        $('#m-footer').removeClass('d-none');
      }
      document.getElementById('barcode').innerHTML = this.values.join(',');
      const url = `/pols/items_summary?barcode=${this.values}&summary=packing`;
      get(url, {
        responseKind: 'turbo-steam',
      });
    } else {
      if (action) {
        if (this.values.indexOf(action) !== -1) {
          const index = this.values.indexOf(action);
          this.values.splice(index, 1);
        }
      }
      const url = `/pols/packing_list?barcode=${this.values}`;
      get(url, {
        responseKind: 'turbo-steam',
      });
    }
  }

  unPacking(e) {
    this.removeBarcode()
    this.getPacking(e.target.value);
    if (document.getElementsByName('un-packing').length === 1) {
      this.pointerNone('remove');
    }
  }

  showSummaryList() {
    this.getPacking('show-summary');
  }

  pointerNone(type) {
    var packingTable = document.getElementById('packingTable')?.children;
    if (packingTable?.length == 2) {
      if (packingTable[1].children.length > 0) {
        var tab = document.getElementsByClassName('menu-tab')[0].children;
        for (let index = 0; index < tab.length; index += 1) {
          if (type == 'add') {
            if (tab[index].className.indexOf('tab-select') == -1) {
              tab[index].classList.add('pointer-none');
              document.getElementById('confirm_cancel')?.classList.remove('pointer-none')
            }
          } else if (type == 'remove') {
            if (tab[index].className.indexOf('pointer-none') > -1) {
              tab[index].classList.remove('pointer-none');
              document.getElementById('confirm_cancel')?.classList.add('pointer-none')
            }
          }
        }
      }
    }
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  openModal(messages) {
    document.getElementById('alert_messages').textContent = messages;
    this.myModal($('#modalBarcode')).show();
    var inputCode = document.getElementById('input-packing')
    if (inputCode?.value) {
      document.getElementById('alert_barcode').textContent = inputCode?.value
    }
    document.getElementById('input-packing').value = ''
  }
}

