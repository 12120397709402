document.addEventListener('turbo:load', () => {
  (function ($) {
    const fullHeight = function () {
      $('.js-fullheight').css('height', $(window).height());

      $(window).on('resize', () => {
        $('.js-fullheight').css('height', $(window).height());
      });
    };

    fullHeight();

    $('#sidebarCollapse').on('click', () => {
      $('#sidebar').toggleClass('active');
    });
  }(jQuery));
});
