import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

// Connects to data-controller="product"
export default class extends Controller {
  connect() {
    this.modalWarning();
  }

  modalWarning() {
    const importWarning = document.getElementById('importWarning');
    if (importWarning) {
      if (importWarning.value.includes('template_error')) {
        this.myModal($('#templateMissingModal')).show();
      } else if (importWarning.value.includes('not_found')) {
        this.myModal($('#importWarningModal')).show();
      } else if (importWarning.value.includes('duplicated')) {
        this.myModal($('#replaceWarningModal')).show();
      }
    }
  }

  downloadInvalidLine() {
    const form = document.getElementById('invalid-line-form');
    form.submit();
  }

  downloadMissingProductMaterial() {
    const form = document.getElementById('missing-product-material-form');
    form.submit();
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }
}
