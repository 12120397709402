import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="check-btn"
export default class extends Controller {
  connect() {
    this.toggleDisplayAddBtn();
  }

  toggleDisplayAddBtn() {
    const inhouseBtn = $('#itsInhouseBtn');

    if (inhouseBtn.is(':checked')) {
      $('#addInhouseBtn').addClass('d-none');
    } else {
      $('#addInhouseBtn').removeClass('d-none');
    }
  }

  checkInhouseMaterials() {
    const inhouseBtn = $('#itsInhouseBtn');
    const inhouseMaterial = $('#inhouseMaterial');

    if (inhouseMaterial.length != 0) {
      const modal = new Modal(document.getElementById('staticBackdropInhouse'));
      modal.show();
      inhouseBtn.prop('checked', false);
      $('#addInhouseBtn').removeClass('d-none');
    }
  }
}
