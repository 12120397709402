import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.initialDataTables();
  }

  initialDataTables() {
    const table = $('table#materialProductTable');

    if (!table.length) {
      return;
    }

    table.dataTable({
      columnDefs: [{ orderable: false, targets: 'nosort' }],
      order: [],
    });

    document.addEventListener('turbo:before-cache', () => {
      const table = $('table#materialProductTable');

      if (!table.length) {
        return;
      }

      const tableConstant = table.DataTable();
      tableConstant.destroy();
    });
  }
}
