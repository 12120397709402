import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['warpInput', 'polItemDetailsContainer', 'goodsStockAttributes'];

  connect() {
    this.check_data_receive_pol()
  }

  check_data_receive_pol() {
    const data = document.getElementsByClassName('dataTables_empty')
    if (data.length == 1) {
      var btn = document.getElementById('btn-submit-receive')
      btn?.classList?.add('pointer-none')
    }
  }

  initialize() {
    this.newInputs = this.cloneInput();
    this.initialSelect2();
    this.initialDataTables();
  }

  change(e) {
    const select = e.target;
    const input = select.parentNode.parentNode;
    const data = JSON.parse(select.options[select.selectedIndex].dataset.product);

    input.querySelector('#goods_stock_style_vendor').value = data.style_vendor;
    input.querySelector('#goods_stock_color').value = data.color;
    input.querySelector('#goods_stock_size').value = data.size;
    input.querySelector('#goods_stock_balance').value = data.balance;
  }

  addMore(e) {
    e.preventDefault();

    const inputs = this.newInputs.cloneNode(true);
    const select = $(inputs).find('select')[0];
    const $select = $(select);

    $select.attr('id', `product_id_${new Date().getTime()}`);
    this.warpInputTarget.appendChild(inputs);

    $select
      .select2()
      .on('select2:select', this.onSelect2Change);
  }

  remove(e) {
    e.preventDefault();
    e.target.parentNode.parentNode.parentNode.remove();
  }

  submit(e) {
    e.preventDefault();

    const inputs = document.querySelectorAll('form .inputs-goods-stock');
    let invalid = false;

    inputs.forEach((input) => {
      const elQty = input.querySelector('#goods_stock_qty');
      const elSelect = input.querySelector('select');
      const elProductId = elSelect.options[elSelect.selectedIndex];

      if (!elQty.value) {
        elQty.classList.add('is-invalid');
        invalid = true;
      } else {
        elQty.classList.remove('is-invalid');
      }

      if (!elProductId.value) {
        elSelect.classList.add('is-invalid');
        invalid = true;
      } else {
        elSelect.classList.remove('is-invalid');
      }
    });

    if (invalid) { return; }

    document.querySelector('#new_goods_stock_form').submit();
  }

  submitGoodsStockByPol(e) {
    e.preventDefault();

    const $checkboxs = $("input[type=checkbox][name='item_detail_ids[]']");

    $checkboxs.each((i, elem) => {
      const $elem = $(elem);
      const checked = $elem.prop('checked');
      const itemDetailId = $elem.val();

      const inputs = $(`#item-detail-${itemDetailId} input[type=hidden]`);
      inputs.prop('disabled', !checked);
    });

    e.currentTarget.submit();
  }

  cloneInput() {
    const inputs = document.querySelector('.inputs-goods-stock');
    const tempInputs = document.querySelector('#temp-inputs');
    const newInputs = tempInputs.appendChild(inputs.cloneNode(true));
    return newInputs;
  }

  changePol(e) {
    const polId = e.target.selectedOptions[0].value;

    if (polId) {
      get(`/goods_stocks/pol/${polId}`, {
        responseKind: 'turbo-steam',
      }).then(() => {
        this.polItemDetailsContainerTarget.classList.remove('d-none');
      });
    } else {
      this.polItemDetailsContainerTarget.classList.add('d-none');
    }
  }

  setPolsItemDetailForm(e) {
    const $elem = $(e.target);
    const data = $elem.data('item-detail');
    const qty = $(
      `#goodsStockInputs #item-detail-${data.id} input[id='goods_stock__qty']`,
    ).val();

    $('#editPolsItemDetailPolsItemDetailId').val(data.pols_item_detail_id);
    $('#editPolsItemDetailId').val(data.id);
    $('#editPolsItemDetailPoc').val(data.order_id);
    $('#editPolsItemDetailSku').val(data.sku);
    $('#editPolsItemDetailProduct').val(data.style_vendor);
    $('#editPolsItemDetailColor').val(data.color);
    $('#editPolsItemDetailSize').val(data.size);
    $('#editPolsItemDetailQty').val(data.qty);
    $('#editPolsItemDetailRemainingQty').val(data.remaining_received);
    $('#editPolsItemDetailStockQty').val(data.stock_qty);
    $('#editPolsItemDetailReceiveQty').val(qty);
  }

  updatePolsItemDetailQty(e) {
    const itemDetailId = $('#editPolsItemDetailId').val();
    const receiveQty = $('#editPolsItemDetailReceiveQty').val();

    $(
      `#goodsStockInputs #item-detail-${itemDetailId} input[id='goods_stock__qty']`,
    ).val(receiveQty);
    $(`#item-detail-${itemDetailId}-row td:eq(-2)`).html(receiveQty);
  }

  deletePolsItemDetail(e) {
    const itemDetailId = $('#editPolsItemDetailId').val();
    const table = $('table#goodsStockSkuListTable').DataTable();
    table.rows(`#item-detail-${itemDetailId}-row`).remove().draw();
    $(`#goodsStockInputs #item-detail-${itemDetailId}`).remove();
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  toggleSelectAllPolsItemDetail(e) {
    $("input[type=checkbox][name='item_detail_ids[]']").prop(
      'checked',
      e.target.checked,
    );
    const polsItemDetsil = $("tbody")[0].children;
    for (let i = 0; i < polsItemDetsil.length; i += 1) {
      const itemDetail = $(`#item-detail-${polsItemDetsil[i].id.split('-')[2]}`)[0].children;
      for (let j = 0; j < itemDetail.length; j += 1) {
        const className = itemDetail[j].className;
        if (!e.target.checked) {
          $(`.${className}`)[0].setAttribute('disabled', 'disabled');
        } else {
          $(`.${className}`)[0].removeAttribute('disabled');
        }
      }
    }
  }

  toggleSelectPolsItemDetail(e) {
    const itemDetsils = $(`#item-detail-${e.target.value}`)[0].children;
    for (let index = 0; index < itemDetsils.length; index++) {
      const className = itemDetsils[index].className;
      if (!e.target.checked) {
        $(`.${className}`)[0].setAttribute('disabled', 'disabled');
      } else {
        $(`.${className}`)[0].removeAttribute('disabled');
      }
    }
  }

  initialSelect2() {
    const select = $('select#pol_id, select#goods_stock_product_id')[0];

    if (!select.length) { return; }

    $(select)
      .select2()
      .on('select2:select', this.onSelect2Change);

    document.addEventListener('turbo:before-cache', () => {
      if (!$(select).data('select2')) { return; }

      $(select).select2('destroy');
    });
  }

  initialDataTables() {
    const table = $('table#goodsStockSkuListTable');

    if (!table.length) { return; }

    table.DataTable({
      destroy: true,
      paging: false,
      columnDefs: [
        { orderable: false, targets: 'nosort' },
      ],
      order: [],
    });
  }
}
