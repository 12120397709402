import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = ['barcode'];

  values = [];

  connect(e) {
    this.pointerNone('add');
    const alert = document.getElementById('alert_receive')
    if (alert) {
      const obj = JSON.parse(alert.value)
      if (obj?.not_found || obj?.blank) {
        this.openModal('Not found this barcode.')
      } else if (obj?.already_scan) {
        this.openModal('The barcode already scan this step.')
      } else if (obj?.pol_complete) {
        this.openModal("This POL already completed, you can't scan this item.")
      } else if (obj?.not_pass_qc) {
        this.openModal("This item not pass QC can not scan this step.")
      }
    }
    document.getElementById('input-receive').value = ''
  }

  removeBarcode() {
    const param = document.getElementById('params_receive')
    if (param?.value) {
      var index = this.values.indexOf(param.value)
      if (index > -1) {
        this.values.splice(index, 1)
      }
    }
  }

  scanBarcode(e) {
    if (e.keyCode === 13) {
      this.removeBarcode()
      const inputReceive = document.getElementById('input-receive')?.value.trim();
      const key = /^\d{1,}-\d{1,}$/;
      if (inputReceive !== '' && key.test(inputReceive)) {
        if (this.values.indexOf(inputReceive) === -1) {
          this.values.push(inputReceive);
          document.getElementById('values').innerHTML = this.values.join(',');
          this.getReceiveItem();
        } else {
          this.openModal('The barcode already scan this step.')
        }
      }else {
        this.openModal('Please enter correct format barcode.')
      }
    }
  }

  focusInputScanBarcode() {
    document.getElementById('input-receive')?.focus();
  }

  getReceiveItem(action) {
    this.barcodeTarget.value = this.values;
    if (action === 'show-summary') {
      if (this.values.length === 0) {
        $('#m-footer').addClass('d-none');
      } else {
        $('#m-footer').removeClass('d-none');
      }
      document.getElementById('barcode').innerHTML = this.values.join(',');
      const url = `/pols/items_summary?barcode=${this.values}&summary=receive`;
      get(url, {
        responseKind: 'turbo-steam',
      });
    } else {
      if (action) {
        var index = this.values.indexOf(action)
        this.values.splice(index, 1)
      }
      const url = `/pols/receive_list?barcode=${this.values}`;
      get(url, {
        responseKind: 'turbo-steam'
      });
    }
  }

  unReceive(e) {
    this.removeBarcode()
    this.getReceiveItem(e.target.value);
    if (document.getElementsByName('un-receive').length === 1) {
      this.pointerNone('remove');
    }
  }

  showSummaryList() {
    this.getReceiveItem('show-summary');
  }

  pointerNone(type) {
    var receiveItemTable = document.getElementById('receiveItemTable')?.children;
    if (receiveItemTable?.length == 2) {
      if (receiveItemTable[1].children.length > 0) {
        var tab = document.getElementsByClassName('menu-tab')[0].children;
        for (let index = 0; index < tab.length; index += 1) {
          if (type == 'add') {
            tab[index].classList.add('pointer-none');
            document.getElementById('confirm_cancel')?.classList.remove('pointer-none')
          } else if (type == 'remove') {
            if (tab[index].className.indexOf('pointer-none') > -1) {
              tab[index].classList.remove('pointer-none');
              document.getElementById('confirm_cancel')?.classList.add('pointer-none')
            }
          }
        }
      }
    }
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  openModal(messages) {
    document.getElementById('alert_messages').textContent = messages;
    this.myModal($('#modalBarcode')).show();
    var inputCode = document.getElementById('input-receive')
    if (inputCode?.value) {
      document.getElementById('alert_barcode').textContent = inputCode?.value
    }
    document.getElementById('input-receive').value = ''
  }
}
