document.addEventListener('turbo:render', initialDropFile);
document.addEventListener('turbo:load', initialDropFile);

function initialDropFile() {
  if (!document.getElementById('input-file-drop')) { return; }

  const inputFile = document.getElementById('input-file-drop');
  const fileName = document.getElementById('input-file-name');
  const dropArea = document.getElementById('drop-area');

  dropArea.style.position = 'relative';
  inputFile.style.opacity = 0;
  inputFile.closest('#drop-area');

  const active = () => {
    inputFile.classList.add('drop-area-input');
    inputFile.style.display = 'inline';
    dropArea.classList.add('drag-or-drop');
  };

  const inactive = () => {
    inputFile.classList.remove('drop-area-input');
    inputFile.style.display = 'none';
    dropArea.classList.remove('drag-or-drop');
  };

  ['dragenter', 'drop'].forEach((evtName) => {
    dropArea.addEventListener(evtName, () => active());
  });

  ['dragleave', 'drop'].forEach((evtName) => {
    inputFile.addEventListener(evtName, () => inactive());
  });

  dropArea.addEventListener('drop', () => inactive());

  inputFile.addEventListener('change', () => {
    const type = ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (type.includes(inputFile.files[0].type)) {
      fileName.innerHTML = inputFile.files[0].name;
    } else {
      fileName.value = '';
      fileName.innerHTML = '';
    }
  });
}
