import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = [
    'pocSelect',
    'pocItemDetailContainer',
    'shippingsItemDetailAttributes',
    'shippingsItemDetailContainer',
    'customerSelect',
    'addPocSkuButton',
    'dropFileButtonNew',
    'dropFileButtonEdit',
  ];

  initialize() {
    this.initialDataTables();
    this.initialAddPocDataTables();
    this.initialSelect2();
    this.showOrder();
  }

  connect() {
    this.modalWarning();
    const params = new URLSearchParams(window.location.search);
    const poc = params.get('poc');
    const customer = params.get('customer');

    if (!poc || !customer) {
      return;
    }

    this.customerSelectTarget.value = customer;
    this.getPocsByCustomer(customer, poc);
  }

  pocChange(e) {
    if (!e.target.selectedOptions[0]) {
      var pocId = this.pocSelectTarget.value;
    } else {
      var pocId = e.target.selectedOptions[0].value;
    }

    const shippingId = $('#shipping_id').val();

    if (+pocId < 1) {
      this.hiddenPocItemDetail();
      return;
    }

    let url = `/pocs/${pocId}/item_details/shipping_item_details`;

    if (shippingId) {
      url += `?shipping_id=${shippingId}`;
    }

    get(url, {
      responseKind: 'turbo-steam',
    })
      .then(() => {
        this.pocItemDetailContainerTarget.classList.remove('d-none');
      })
      .catch(() => {
        this.hiddenPocItemDetail();
      });
  }

  customerChange(e) {
    const customerId = e.target.selectedOptions[0].value;

    if (customerId) {
      this.getPocsByCustomer(customerId);
    } else {
      $('select#poc').prop('disabled', false);
    }
  }

  getPocsByCustomer(customerId, pocSelected = null) {
    const target = this.pocSelectTarget.id;
    const url = `/customers/${customerId}/poc_options?target=${target}`;
    const select = $('select#poc');
    get(url, {
      responseKind: 'turbo-steam',
    }).then(() => {
      this.dropFileButtonNewTarget.disabled = false;
      $(select).prop('disabled', false);
      if (pocSelected) {
        setTimeout(() => {
          this.pocSelectTarget.value = pocSelected;
          this.pocSelectTarget.dispatchEvent(new Event('change'));
        }, 0);
      }
    });
  }

  addItemDetails() {
    this.addPocSkuButtonTarget.disabled = true;

    const $checkboxs = $(
      "input[type=checkbox][name='item_details_ids[]']:checked"
    );
    const itemDetails = $checkboxs
      .filter((i, e) => {
        const itemDetailId = $(e).val();
        return !$(`#item_detail_${itemDetailId}`).length;
      })
      .map((i, e) => {
        const $checkbox = $(e);
        return $checkbox.data('item-detail');
      })
      .get();

    const $shippingsItemDetailAttributes = $(
      this.shippingsItemDetailAttributesTarget
    );

    if (itemDetails.length > 0) {
      $shippingsItemDetailAttributes.append(
        this.buildItemDetailInputs(itemDetails)
      );
      this.buildItemDetailRows(itemDetails);
      this.shippingsItemDetailContainerTarget.classList.remove('d-none');
    }
  }

  setShippingsItemDetailForm(e) {
    const $elem = $(e.target);
    const data = $elem.data('item-detail');
    const qty = $(`#item_detail_${data.id} input[name$='[qty]']`).val();

    $('#shippingsItemDetailItemDetailId').val(data.item_detail_id);
    $('#shippingsItemDetailId').val(data.id);
    $('#shippingsItemDetailPoc').val(data.order_id);
    $('#shippingsItemDetailSku').val(data.sku);
    $('#shippingsItemDetailProduct').val(data.style_vendor);
    $('#shippingsItemDetailColor').val(data.color);
    $('#shippingsItemDetailSize').val(data.size);
    $('#shippingsItemDetailQty').val(data.qty);
    $('#shippingsItemDetailRemainingShipped').val(data.remaining_shipped);
    $('#shippingsItemDetailStockQty').val(data.stock_qty);
    $('#shippingsItemDetailPickQty').val(qty);
    $("button[id^='editModalCancel']").attr(
      'id',
      `editModalCancel${data.item_detail_id}`
    );
    $("button[id^='editModelDelete']").attr(
      'id',
      `editModelDelete${data.item_detail_id}`
    );
  }

  updateShippingsItemDetailQty(e) {
    const itemDetailId = $('#shippingsItemDetailId').val();
    const qty = $('#shippingsItemDetailPickQty').val();

    $(`#item_detail_${itemDetailId} input[name$='[qty]']`).val(qty);
    $(`#item-detail-${itemDetailId}-row td:eq(-2)`).html(qty);
  }

  confirmDelete(e) {
    const itemDetailId = $('#shippingsItemDetailId').val();
    const table = $('table#shippingsItemDetailTable').DataTable();

    if (e.target.id == 'editModelDeletenull') {
      $(`#item_detail_${itemDetailId}`).remove();
      table.rows(`#item-detail-${itemDetailId}-row`).remove().draw();
      this.hideShippingsItemDetailContainer();
    } else {
      this.myModal($(`#confirmDeleteModal-${e.target.id}`)).show();
    }
  }

  hideShippingsItemDetailContainer() {
    const $shippingsItemDetailAttributes = $(
      this.shippingsItemDetailAttributesTarget
    );
    const itemDetailCount = $shippingsItemDetailAttributes.find('div').length;

    if (itemDetailCount === 0) {
      this.shippingsItemDetailContainerTarget.classList.add('d-none');
    }
  }

  cancelDelete(e) {
    this.myModal($('#editShippingsItemDetailModal')).show();
  }

  toggleSelectAllItemDetail(e) {
    $("input[type=checkbox][name='item_details_ids[]']").prop(
      'checked',
      e.target.checked
    );
  }

  hiddenPocItemDetail() {
    this.pocItemDetailContainerTarget.classList.add('d-none');
  }

  buildItemDetailRows(itemDetails) {
    const table = $('table#shippingsItemDetailTable').DataTable();
    const isCreate = !$('#shipping_id').val();

    itemDetails.map((item) => {
      const btn = $('<i class="fal fa-edit color-blue pointer"></i>')
        .attr({
          'data-bs-toggle': 'modal',
          'data-bs-target': '#editShippingsItemDetailModal',
          'data-action': 'click->shipping#setShippingsItemDetailForm',
          'data-item-detail': JSON.stringify(item),
        })
        .get(0).outerHTML;

      const size = isCreate
        ? item.size
        : { display: item.size, '@data-order': item.size_order };

      table.row
        .add([
          item.order_id,
          item.sku,
          item.style_vendor,
          item.color,
          size,
          item.qty,
          item.remaining_shipped,
          item.stock_qty,
          item.remaining_shipped,
          btn,
        ])
        .node().id = `item-detail-${item.id}-row`;
      table.draw();
    });
  }

  buildItemDetailInputs(itemDetails) {
    let index = new Date().getTime();
    return itemDetails.map((item) => {
      index += 1;
      return $('<div>')
        .attr({ id: `item_detail_${item.id}` })
        .append(
          $('<input>').attr({
            type: 'hidden',
            name: `shipping[shippings_item_details_attributes][${index}][item_detail_id]`,
            value: item.id,
          })
        )
        .append(
          $('<input>').attr({
            type: 'hidden',
            name: `shipping[shippings_item_details_attributes][${index}][qty]`,
            value: item.remaining_shipped,
          })
        );
    });
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  initialDataTables() {
    const table = 'table#shippingsItemDetailTable';

    if (!$(table).length) {
      return;
    }

    if (!$.fn.DataTable.isDataTable(table)) {
      $(table).dataTable({
        columnDefs: [{ orderable: false, targets: 'nosort' }],
        order: [],
      });
    }

    const pickTable = 'table#pickItemDetailTable';

    if (!$(pickTable).length) {
      return;
    }

    if (!$.fn.DataTable.isDataTable(pickTable)) {
      $(pickTable).dataTable({
        columnDefs: [{ orderable: false, targets: 'nosort' }],
        order: [],
      });
    }
  }

  initialAddPocDataTables() {
    const table = 'table#pocItemDetailTable';

    if (!$(table).length) {
      return;
    }

    if (!$.fn.DataTable.isDataTable(table)) {
      $(table).DataTable({
        paging: false,
        columnDefs: [{ orderable: false, targets: 0 }],
        order: [],
      });
    }
  }

  showDropFile() {
    const dropFile = document.getElementById('drop-sku-file');
    dropFile.classList.remove('d-none');
  }

  submitSkuMissing() {
    document.forms.formSkuWarning.submit();
    const modal = document.getElementById('dismissSkuWarningModal');
    modal.click();
    this.dropFileButtonNewTarget.disabled = false;
    this.shippingsItemDetailContainerTarget.classList.add('d-none');
  }

  modalWarning() {
    const formatFile = document.getElementById('formatFileInvalid');
    if (formatFile) {
      if (formatFile.value === 'true') {
        const btnDownload = document.getElementById('shipping_file');
        btnDownload?.classList.add('d-none');
        this.myModal($('#formatFileInvalidModal')).show();
        this.unDisabledBtn();
      }
    }

    const notEnoughQty = document.getElementById('skuNotEnoughQty');
    if (notEnoughQty?.value === 'true') {
      this.myModal($('#skuNotEnoughQtyModal')).show();
    }

    const skuWarning = document.getElementById('skuWarning');
    if (skuWarning?.value === 'true') {
      this.myModal($('#skuWarningModal')).show();
      const btnDownload = document.getElementById('shipping_file');
      btnDownload?.classList.add('d-none');
      this.unDisabledBtn();
    }

    const shippingFile = document.getElementById('download_shipping_file');
    if (shippingFile) {
      const upFile = document.getElementById('upload_shipping_file');
      if (upFile) {
        upFile.disabled = true;
      }
    }

    const shippingByFile = document.getElementById('shippingByFile');
    if (shippingByFile && shippingByFile.value === 'true') {
      this.shippingsItemDetailAttributesTarget.replaceChildren();
      const table = $('table#shippingsItemDetailTable').DataTable();
      table.rows().remove().draw();
      this.unDisabledBtn();
    }
  }

  submitSkuNotEnoughQty() {
    dismissModalShipping.click();
    const modal = document.getElementById('dismissSkuNotEnoughQtyModal');
    modal.click();
  }

  unDisabledBtn() {
    const shippingId = document.getElementById('shipping_id');
    if (shippingId.value) {
      this.dropFileButtonEditTarget.disabled = false;
    } else {
      this.dropFileButtonNewTarget.disabled = false;
    }
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  initialSelect2() {
    const select = $('select#poc');

    if (!select.length) {
      return;
    }
    if ($(select).data('select2')) {
      return;
    }

    $(select)
      .select2({ tags: true })
      .on('select2:select select2:unselect', this.onSelect2Change);

    if (!shipping_id?.value) {
      $(select).prop('disabled', true);
    }

    document.addEventListener('turbo:before-cache', () => {
      if (!$(select).data('select2')) {
        return;
      }

      $(select).select2('destroy');
    });
  }

  showOrder() {
    const pocs = document.getElementById('shipping_pocs');
    if (!pocs || !pocs.value) {
      return;
    }

    const selected = pocs.value.split(',');
    const select = $('select#poc');
    const options_ids = [...$('select#poc option')].map((op) => op.text);
    const ids = selected.map((x) => x.split(':')[0]);

    $(select).select2().val(ids).trigger('change');
  }

  async showPickItemDetails(e) {
    await this.removePickItemDetails();
    await this.addSpinner();
    await this.getPickItemDetails(e);
  }

  getPickItemDetails(e) {
    const data = e.target.dataset;
    boxName.innerHTML = `Box #${data.boxname}`;
    this.myModal($('#pickItemDetailModal')).show();
    const url = `/shippings/${shipping_id.value}/pick_items/${data.pickid}/edit`;
    get(url, {
      responseKind: 'turbo-steam',
    });
    if (pickItemButton.href.split('?') === 1) {
      pickItemButton.href += `?boxNO=${data.boxname}`;
    } else {
      const url = pickItemButton.href.split('?')[0];
      pickItemButton.href = `${url}?boxNO=${data.boxname}`;
    }
  }

  removePickItemDetails() {
    $('#pickItemDetailModal .content .table-custom').html('');
  }

  addSpinner() {
    const element = $('#pickItemDetailModal .content .table-custom');

    element.append(`
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status"></div>
      </div>
    `);
  }

  changePocIds(e) {
    const selectPocIds = e.target.selectedOptions;
    const pocIds = Array.from(selectPocIds, (option) => option.value);
    shipping_poc_ids.value = pocIds.join(' ');
  }

  submitMissing() {
    document.getElementById('dismissSkuWarningModal')?.click();
  }
}
