import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="inhouse-item"
export default class extends Controller {
  static targets = ['inhouseItem', 'cost', 'selectInhouse', 'qty'];

  connect() {
    const inhouse_item = this.selectInhouseTarget.selectedOptions[0].getAttribute('inhouse_item');
    const cost = this.selectInhouseTarget.selectedOptions[0].getAttribute('cost');

    this.inhouseItem(inhouse_item);
    this.applyCost(cost);
    this.initialSelect2(this.selectInhouseTarget);
  }

  change(event) {
    const inhouse_item = event.target.selectedOptions[0].getAttribute('inhouse_item');
    const cost = event.target.selectedOptions[0].getAttribute('cost');

    this.inhouseItem(inhouse_item);
    this.applyCost(cost);
  }

  inhouseItem(inhouse_item) {
    if (inhouse_item == null) {
      inhouse_item = '';
    } else {
      inhouse_item = 'Inhouse';
    }

    this.inhouseItemTarget.value = inhouse_item;
  }

  applyCost(cost) {
    this.costTarget.value = (Math.round(Number(cost) * 100) / 100).toFixed(2);
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  preventSelectDuplicateProduct(e) {
    const $elem = $(e.params.args.data.element);
    const inhouseId = $elem.val();
    const existIds = $("select[name^='product[product_inhouses_attributes]']")
      .map((i, elem) => $(elem).val())
      .get();

    if (existIds.includes(inhouseId)) {
      e.preventDefault();
    }
  }

  initialSelect2(select) {
    if (!select.length) { return; }
    if ($(select).data('select2')) { return; }

    $(select)
      .select2()
      .on('select2:selecting', this.preventSelectDuplicateProduct)
      .on('select2:select', this.onSelect2Change);
  }
}
