import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';


export default class extends Controller {
  static targets = ['barcode', 'qcStatus'];

  values = [];

  connect() {
    this.pointerNone('add');
    const alert = document.getElementById('alert_qc')
    if (alert) {
      const obj = JSON.parse(alert.value)
      if (obj?.not_found || obj?.blank) {
        this.openModal('Not found this barcode.')
      }else if (obj?.already_scan) {
        this.openModal('The barcode already scan this step.')
      }else if (obj?.pol_complete) {
        this.openModal("This POL already completed, you can't scan this item.")
      }
    }
    var input = document.getElementById('input-qc');
    if (input) {
      input.value  = ''
    }
  }

  focusInputScanBarcode() {
    document.getElementById('input-qc')?.focus();
  }

  removeBarcode() {
    const param = document.getElementById('params_qc')
    if (param?.value) {
      var index = this.values.indexOf(param.value)
      if (index > -1) {
        this.values.splice(index, 1)
      }
    }
  }

  scanBarcode(e) {
    if (e.keyCode === 13) {
      this.removeBarcode()
      const inputQc = document.getElementById('input-qc')?.value.trim();
      const key = /^\d{1,}-\d{1,}$/;
      if (inputQc !== '' && key.test(inputQc)) {
        if (this.values.indexOf(inputQc) === -1) {
          this.values.push(inputQc);
          document.getElementById('values').innerHTML = this.values.join(',');
          this.getQc();
        } else {
          this.openModal('The barcode already scan this step.')
        }
      }else {
        this.openModal('Please enter correct format barcode.')
      }
    }
  }

  getQc(action) {
    this.barcodeTarget.value = this.values;
    if (action === 'show-summary') {
      if (this.values.length === 0) {
        $('#m-footer').addClass('d-none');
      } else {
        $('#m-footer').removeClass('d-none');
      }
      document.getElementById('barcode').innerHTML = this.values.join(',');
      const url = `/pols/items_summary?barcode=${this.values}&status=${this.qcStatusTarget.value}&summary=qc`;
      get(url, {
        responseKind: 'turbo-steam',
      });
    } else {
      if (action) {
        var index = this.values.indexOf(action)
        this.values.splice(index, 1)
      }
      const url = `/pols/qc_list?barcode=${this.values}&status=${this.qcStatusTarget.value}`;
      get(url, {
        responseKind: 'turbo-steam',
      });
    }
  }

  unQc(e) {
    this.removeBarcode()
    this.getQc(e.target.value);
    if (document.getElementsByName('un-qc').length === 1) {
      this.pointerNone('remove');
    }
  }

  showSummaryList() {
    this.getQc('show-summary');
  }

  pointerNone(type) {
    var qcTable = document.getElementById('qcTable')?.children;
    if (qcTable?.length == 2) {
      if (qcTable[1].children.length > 0) {
        var tab = document.getElementsByClassName('menu-tab')[0].children;
        for (let index = 0; index < tab.length; index += 1) {
          if (type == 'add') {
            if (tab[index].className.indexOf('tab-select') == -1) {
              tab[index].classList.add('pointer-none');
              document.getElementById('confirm_cancel')?.classList.remove('pointer-none')
            }
          } else if (type == 'remove') {
            if (tab[index].className.indexOf('pointer-none') > -1) {
              tab[index].classList.remove('pointer-none');
              document.getElementById('confirm_cancel')?.classList.add('pointer-none')
            }
          }
        }
      }
    }
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  openModal(messages) {
    document.getElementById('alert_messages').textContent = messages;
    this.myModal($('#modalBarcode')).show();
    var inputCode = document.getElementById('input-qc')
    if (inputCode?.value) {
      document.getElementById('alert_barcode').textContent = inputCode?.value
    }
    document.getElementById('input-qc').value = ''
  }
}
