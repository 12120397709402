import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  connect() {
    this.cloneInput();

    const select = $('#input-container select#poc_ids').first();
    this.initialSelect2(select);
  }

  submitButton(e) {
    const exportValue = e.target.id;

    const pocIds = Array.from(poc_ids, (op) => op.value).filter((id) => id);
    if (pocIds.length <= 0) return;

    $(exportType).val(exportValue);
    material_report.submit();
  }
  
  submitPocStatus() {
    const pocIds = Array.from(poc_ids, (op) => op.value).filter((id) => id);
    if (pocIds.length <= 0) return;
    
    $(pocStatusIds).val(pocIds);
    poc_status_report.submit();
  }

  submit(e) {
    e.preventDefault();

    const $form = $(e.currentTarget);
    const url = $form.attr('action');
    const query = $form.serialize();

    window.open(`${url}?${query}`, '_blank');

    Turbo.visit('/pocs');
  }

  pocChange(e) {
    const select = e.target;
    const $option = $(select.options[select.selectedIndex]);
    const data = $option.data('poc');
    const $customerInput = $(select)
      .parent()
      .siblings('.customer-name')
      .find('input[name="customer_name"]');

    $customerInput.val(data.customer);
  }

  addMore() {
    const $template = $('#input-template .row').first().clone();
    const $container = $('#input-container');
    $container.append($template);

    const select = $template.find('select#poc_ids').first();
    this.toggleDisplayRemoveBtn();
    this.initialSelect2(select);
  }

  remove(e) {
    const $inputRow = $(e.currentTarget).parent().parent();
    $inputRow.remove();
    this.toggleDisplayRemoveBtn();
  }

  toggleDisplayRemoveBtn() {
    const inputCount = $('#input-container .row').length;

    if (inputCount > 1) {
      $('.remove-col').removeClass('d-none');
    } else {
      $('.remove-col').addClass('d-none');
    }
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  initialSelect2(elem) {
    const select = $(elem);

    if (!select.length) {
      return;
    }

    $(select).select2().on('select2:select', this.onSelect2Change);
  }

  cloneInput() {
    const $inputs = $('#input-container .row').first();
    const $tempDiv = $('#input-template');
    $tempDiv.append($inputs.clone());
  }
}
