import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import * as bootstrap from 'bootstrap';

// Connects to data-controller="goods-stock-receive"
export default class extends Controller {
    static targets = ['barcode'];

  values = [];

  connect(e) {
    this.pointerNone();
    const alert = document.getElementById('alert_receive')
    if (alert) {
      const obj = JSON.parse(alert.value)
      if (obj?.not_found || obj?.blank) {
        this.openModal('Not found this barcode.')
      }else if (obj?.already_scan) {
        this.openModal('The barcode already scan this step.')
      }else if (obj?.pol_complete) {
        this.openModal("This POL already completed, you can't scan this item.")
      }else if (obj?.not_pass_qc) {
        this.openModal("This item not pass QC can not scan this step.")
      }
    }
    document.getElementById('input-receive').value = ''
  }

  focusInputScanBarcode() {
    document.getElementById('input-receive')?.focus();
  }

  removeBarcode() {
    const param = document.getElementById('params_receive')
    if (param?.value) {
      var index = this.values.indexOf(param.value)
      if (index > -1) {
        this.values.splice(index, 1)
      }
    }
  }

  scanBarcode(e) {
    if (e.keyCode === 13) {
      this.removeBarcode()
      const inputReceive = document.getElementById('input-receive')?.value.trim();
      const key = /^\d{1,}-\d{1,}$/;
      if (inputReceive !== '' && key.test(inputReceive)) {
        if (this.values.indexOf(inputReceive) === -1) {
          this.values.push(inputReceive);
          document.getElementById('values').innerHTML = this.values.join(',');
          this.getReceiveItem();
        } else {
          this.openModal('The barcode already scan this step.')
        }
      }else {
        this.openModal('Please enter correct format barcode.')
      }
    }
  }

  getReceiveItem(action) {
    this.barcodeTarget.value = this.values;
    if (action === 'show-summary') {
      if (this.values.length === 0) {
        $('#m-footer').addClass('d-none');
      } else {
        $('#m-footer').removeClass('d-none');
      }
      document.getElementById('barcode').innerHTML = this.values.join(',');
      const url = `/goods_stocks/items_summary?barcode=${this.values}&summary=receive`;
      get(url, {
        responseKind: 'turbo-steam',
      });
    } else {
      if (action) {
        var index = this.values.indexOf(action)
        this.values.splice(index, 1)
      }
      const url = `/goods_stocks/receive_list?barcode=${this.values}`;
      get(url, {
        responseKind: 'turbo-steam'
      });
    }
  }

  unReceive(e) {
    this.removeBarcode()
    this.getReceiveItem(e.target.value);
  }

  pointerNone(type) {
    var receiveItemTable = document.getElementById('receiveItemTable')?.children;
    if (receiveItemTable?.length == 2) {
      if (receiveItemTable[1].children.length > 0) {
          document.getElementById('confirm_cancel')?.classList.remove('pointer-none')
        }else {
          document.getElementById('confirm_cancel')?.classList.add('pointer-none')
        }
      }
      }

  showSummaryList() {
    this.getReceiveItem('show-summary');
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  openModal(messages) {
    document.getElementById('alert_messages').textContent = messages;
    this.myModal($('#modalBarcode')).show();
    var inputCode = document.getElementById('input-receive')
    if (inputCode?.value) {
      document.getElementById('alert_barcode').textContent = inputCode?.value
    }
    document.getElementById('input-receive').value = ''
  }

  receiveGoodsStocks(e) {
    var data = document.getElementsByClassName("data-receive")
    for (let i = 0; i < data.length; i += 1) {
      var receive = JSON.parse(data[i].dataset.receive);
      document.getElementById("goodsStockInputs").innerHTML += `
        <div id="item-detail-${i}">
          <input type="hidden" name="goods_stock[][pol_id]" id="goods_stock__pol_id" value="${receive.pol_id}" class="item_${receive.pols_item_detail_id}_pols_item_detail_id_${receive.pol_id}">
          <input type="hidden" name="goods_stock[][pols_item_detail_id]" id="goods_stock__pols_item_detail_id" value="${receive.pols_item_detail_id}" class="item_${receive.pols_item_detail_id}_pols_item_detail_id_${receive.pols_item_detail_id}">
          <input type="hidden" name="goods_stock[][product_id]" id="goods_stock__product_id" value="${receive.product_id}" class="item_1_product_id_2">
          <input type="hidden" name="goods_stock[][remaining_qty]" id="goods_stock__remaining_qty" value="${receive.remaining_qty}" class="item_${receive.pols_item_detail_id}_remaining_qty_${receive.remaining_qty}">
          <input type="hidden" name="goods_stock[][qty]" id="goods_stock__qty" value="${receive.qty}" class="item_${receive.pols_item_detail_id}_qty_${receive.qty}">
        </div>
    `}
    document.forms["receive_goods_stocks"].submit()
  }
}
