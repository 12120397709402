import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

// Connects to data-controller='receive-report'
export default class extends Controller {
  static targets = ['checkBoxParent', 'hiddenItemSelectedIds', 'state', 'status', 'hiddenState', 'hiddenStatus', 'showItemSelectedIds']

  connect() {
    this.initialSelect2();
    this.initialFilter();
    this.hideMenu();
    this.initialCheckbox();
    this.setStatus();
    this.checkReport()
  }

  checkReport() {
    const reportBlank = document.getElementById('report_blank')
    const isPointerNone = document.getElementById('btn-print-report').classList.contains('pointer-none')

    if (reportBlank) {
      if (!isPointerNone) {
        document.getElementById('btn-print-report')?.classList.add('pointer-none','btn-reprint')
        document.getElementById('btn-print-report')?.classList.remove('btn--primary')
      }
    } else {
      if (isPointerNone) {
        document.getElementById('btn-print-report')?.classList.remove('pointer-none','btn-reprint')
        document.getElementById('btn-print-report')?.classList.add('btn--primary')
      }
    }
  }

  initialCheckbox() {
    const itemIds = JSON.parse(localStorage.getItem('item_ids'))

    if (itemIds != null) {
      itemIds.forEach(id => {
        this.hiddenItemSelectedIdsTargets.map(x => {
          if(x.value == id) {
            x.checked = true
          }
        })
        this.showItemSelectedIdsTargets.map(x => {
          if(x.value == id) {
            x.checked = true
          }
        })
      })
      this.selectedIdsSave()
    }
  }

  selectedIdsSave() {
    const hiddenItemSelectedIdsTargets = this.hiddenItemSelectedIdsTargets
    var item_ids = []

    hiddenItemSelectedIdsTargets.map(x => {
      if(x.checked) {
        item_ids.push(x.value)
      }
    })

    localStorage.setItem('item_ids', JSON.stringify(item_ids))
  }

  clearLocalStorage() {
    localStorage.removeItem('item_ids')
  }

  getQcStatus(index) {
    const qc_status = ['qc_pass qc_failed', 'qc_pass', 'qc_failed']
    return qc_status[index]
  }

  toggleChildren() {
    if (this.checkBoxParentTarget.checked) {
      this.hiddenItemSelectedIdsTargets.map(x => { if(x.disabled == false) { x.checked = true } })
      this.showItemSelectedIdsTargets.map(x => { if(x.disabled == false) { x.checked = true } })
    } else {
      this.hiddenItemSelectedIdsTargets.map(x => x.checked = false)
      this.showItemSelectedIdsTargets.map(x => x.checked = false)
    }

    this.selectedIdsSave()
  }

  toggleCheckboxChild() {
    this.hiddenItemSelectedIdsTargets.map(ch => {
      this.showItemSelectedIdsTargets.map(cs => {
        if(cs.checked == true) {
          if(cs.value == ch.value) {
            ch.checked = true
          }
        }else {
          if(cs.value == ch.value) {
            ch.checked = false
          }
        }
      })
    })

    this.selectedIdsSave()
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  initialSelect2() {
    const select = $('select#polSelect')[0];

    if (!select.length) { return; }
    $(select).select2().on('select2:select', this.onSelect2Change);

    // set style of select2 width from auto to 100%
    const select2Dom = document.getElementsByClassName('select2-container')[0]
    select2Dom.classList.add('select2-w-100-p')
  }

  clearStatusOption(statusList) {
    statusList.innerHTML = ''
  }

  hideMenu() {
    const state = this.stateTarget.value;
    const reprintBarcodeBtn = document.getElementById('reprintBarcode')
    var editQC = document.getElementsByName('editQC')
    const statusSelect = document.getElementById('statusSelectForm')
    const checkboxAll = document.getElementById('checkboxAll')
    checkboxAll.checked = false

    if (state == 'received' || state == 'packed' || state == 'reprint') {
      reprintBarcodeBtn.style.display = 'none'
      checkboxAll.style.display = 'none'
      this.showItemSelectedIdsTargets.map(x => { x.style.display = 'none'; x.checked = false })
      this.hiddenItemSelectedIdsTargets.map(x => x.checked = false )
      statusSelect.style.display = 'none'
      for (var obj of editQC) {
        obj.classList.add('d-none')
      }
    } else if(state == 'Q.C.') {
      reprintBarcodeBtn.style.display = 'none'
      checkboxAll.style.display = 'none'
      this.showItemSelectedIdsTargets.map(x => { x.style.display = 'none'; x.checked = false })
      this.hiddenItemSelectedIdsTargets.map(x => x.checked = false )
      statusSelect.style.display = 'block'
      for (var obj of editQC) {
        obj.classList.remove('d-none')
      }
    } else if(state == 'open') {
      reprintBarcodeBtn.style.display = 'block'
      checkboxAll.style.display = 'inline-block'
      this.showItemSelectedIdsTargets.map(x => x.style.display = 'inline-block')
      statusSelect.style.display = 'none'
      for (var obj of editQC) {
        obj.classList.add('n-none')
      }
    } else {
      reprintBarcodeBtn.style.display = 'block'
      checkboxAll.style.display = 'inline-block'
      this.showItemSelectedIdsTargets.map(x => x.style.display = 'inline-block')
      statusSelect.style.display = 'none'
      for (var obj of editQC) {
        obj.classList.add('d-none')
      }
    }
  }

  setStatusOption(selectValueIndex) {
    const stateList = this.stateTarget;
    const statusList = this.statusTarget;
    const qcStatus = [];

    if (selectValueIndex == undefined || selectValueIndex == null) {
      selectValueIndex = 0
    }

    this.clearStatusOption(statusList)

    if (stateList.value == 'Q.C.') {
      qcStatus.push('All');
      qcStatus.push('Pass');
      qcStatus.push('Fail');
    } else {
      qcStatus.push('All');
    }

    qcStatus.map((x, i) => {
      const status = new Option(x, i);
      statusList.options.add(status) ;
    })

    statusList.value = selectValueIndex
  }

  selectState() {
    const state = this.stateTarget.value;
    const hiddenState = this.hiddenStateTarget;
    const hiddenStatus = this.hiddenStatusTarget;

    if ( state == 'Q.C.' ) {
      hiddenState.value = state
      this.selectQCType();
    } else {
      hiddenState.value = state
      hiddenStatus.value = state
    }

    this.hideMenu();
    this.setStatusOption();
  }

  selectQCType() {
    const hiddenStatus = this.hiddenStatusTarget;
    const status = this.statusTarget;
    hiddenStatus.value = this.getQcStatus(status.value)
  }

  initialFilter() {
    const state = document.getElementById('state');
    const status = document.getElementById('status');
    const hiddenState = this.hiddenStateTarget;
    const hiddenStatus = this.hiddenStatusTarget;

    if (hiddenState.value == 'qc_pass' || hiddenState.value == 'qc_failed' || hiddenState.value == 'qc_pass qc_failed') {
      state.value = 'Q.C.'
      this.setStatusOption(this.changeQcTextToValue(hiddenState.value))
    } else {
      state.value = hiddenState.value
      this.setStatusOption()
    }
  }

  changeQcTextToValue(qc) {
    if (qc == 'qc_pass qc_failed') return 0
    if (qc == 'qc_pass') return 1
    if (qc == 'qc_failed') return 2
  }

  async submitPassword() {
    const loading = document.getElementById('loading')
    const passwordForm = document.getElementById('passwordForm')
    const error = document.getElementById('error')

    passwordForm.style.display = 'none'
    loading.style.display = 'flex'

    const request = new FetchRequest('post', '/check_user_password',
    {
      body: JSON.stringify({ password: document.getElementById('pass_check').value })
    })
    const response = await request.perform()
    const json_res = await response.json

    const itemIdsForm = document.getElementById('item-form-ids')


    if (json_res.correct) {
      this.submitReprintBarcodeItemIds(itemIdsForm)
    } else {
      error.style.display = 'flex'
      passwordForm.style.display = 'flex'
      loading.style.display = 'none'
    }
  }

  submitReprintBarcodeItemIds(form) {
    form.setAttribute('target', '_blank')
    form.submit()
    this.clearLocalStorage()
    location.reload()
  }

  checkItemChecked() {
    if (this.hiddenItemSelectedIdsTargets.map(x => x.checked).includes(true)) {
      this.myModal(document.getElementById('checkPassModal')).show();
    } else {
      alert('Please select some of item')
    }
  }

  submitForm() {
    document.getElementById('searchReport').submit();
  }

  confirmEditQcStatus(e) {
    var item = JSON.parse(e.target.dataset.item);
    document.getElementById('item_id').value = item.id
    document.getElementById('old_status').value = item.last_status
    document.getElementById('barcodeQcModal').innerText = `Item : ${item.barcode}`
    this.myModal(document.getElementById('confirmeditQcModal')).show();
  }

  editQcStatus(e) {
    const item_id = document.getElementById('item_id').value
    const new_status = e.params.status
    const old_status = document.getElementById('old_status').value
    var status_item_ids = JSON.parse(localStorage.getItem('status_item_ids')) || {}
    var data = {}
    data['new'] = new_status
    data['old'] = old_status

    if (status_item_ids) {
      status_item_ids[item_id] = data
      localStorage.setItem('status_item_ids', JSON.stringify(status_item_ids))
      document.getElementById(`status-item-${item_id}`).innerText = new_status.toUpperCase()
    } else {
      status_item_ids[item_id] = data
      localStorage.setItem('status_item_ids', JSON.stringify(status_item_ids))
    }
    this.setBtnSubmit(true)
  }

  setStatus(e) {
    var statusItems = document.getElementsByName('status-item')
    var status_item = JSON.parse(localStorage.getItem('status_item_ids')) || {}
    for (var st of statusItems) {
      var data = JSON.parse(st.dataset.item)
      if (status_item[data.id] !=  undefined ) {
        this.setBtnSubmit(true)
        document.getElementById(`status-item-${data.id}`).innerText = status_item[data.id]['new'].toUpperCase()
      } else {
        if (data.last_status.indexOf('qc') == -1) {
          document.getElementById(`icon-item-${data.id}`)?.classList.add('d-none')
        }
        document.getElementById(`status-item-${data.id}`).innerText = data.last_status.toUpperCase()
      }
    }
  }

  setBtnSubmit(type) {
    if (type) {
      document.getElementById('cancelEditQc')?.classList.remove('pointer-none')
      document.getElementById('submitEditQc')?.classList.remove('pointer-none')
    } else {
      document.getElementById('cancelEditQc')?.classList.add('pointer-none')
      document.getElementById('submitEditQc')?.classList.add('pointer-none')
    }
  }

  clesrStatusItems() {
    const statusItems = JSON.parse(localStorage.getItem('status_item_ids'))
    for (const key in statusItems) {
      var item = document.getElementById(`status-item-${key}`)
      if (item) {
        item.innerText = statusItems[key]['old'].toUpperCase()
      }
    }
    localStorage.removeItem("status_item_ids");
  }

  submitEditQcStatus() {
    const statusItems = JSON.parse(localStorage.getItem('status_item_ids'))
    for (const key in statusItems) {
      const item = document.createElement('div')
      item.classList.add(`item-${key}`)

      const id = document.createElement('input')
      id.name = "items[][id]"
      id.value = key

      const status = document.createElement('input')
      status.name = "items[][status]"
      status.value = statusItems[key]['new']

      item.append(id, status)
      document.getElementById('itemQcStatus')?.append(item)
    }
    document.forms["form_update_qc_status"].submit()
    localStorage.removeItem("status_item_ids");
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

}
