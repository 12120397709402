import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = ['style_vendor', 'color', 'size', 'category'];

  initialize() {
    this.initialDataTablesSku();
    this.initialDataTablesPocInhouse();
  }

  change(event) {
    const style_vendor = event.target.selectedOptions[0].getAttribute('style_vendor');
    const color = event.target.selectedOptions[0].getAttribute('color');
    const size = event.target.selectedOptions[0].getAttribute('size');
    const category = event.target.selectedOptions[0].getAttribute('category');
    this.poc(style_vendor, color, size, category);
  }

  poc(style, color, size, category) {
    this.style_vendorTarget.value = style;
    this.colorTarget.value = color;
    this.sizeTarget.value = size;
    this.categoryTarget.value = category;
  }

  confirmDelete(e) {
    $(`#modalCancel${e.target.id}`).trigger('click');
    this.myModal($(`#confirmDeleteModal-${e.target.id}`)).show();
  }

  cancelDelete(e) {
    this.myModal($(`#editskuModalToggle${e.target.id}`)).show();
  }

  toggleEditValue(e) {
    const id = e.params.id;
    if (document.getElementById(`newQty-${id}`)?.hasAttribute('disabled')) {
      document.getElementById(`newQty-${id}`)?.removeAttribute('disabled')
    } else {
      document.getElementById(`newQty-${id}`)?.setAttribute('disabled', 'true')
    }
  }

  setQty(e) {
    const id = e.params.id;
    let stockQty = parseInt(document.getElementById(`stockQty-${id}`).value);
    stockQty = stockQty > 0 ? stockQty : 0;
    const newQty = parseInt(e.target.value);
    const needQty = parseInt(document.getElementById(`needQty-${id}`).value);

    if (newQty >= (needQty - stockQty)) {
      document.getElementById(`item_details-${id}-qty`).value = newQty
    }
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  initialDataTablesSku() {
    const table = 'table#pocSkuLists';

    if (!$(table).length) { return; }

    if (!$.fn.DataTable.isDataTable(table)) {
      $(table).dataTable({
        columnDefs: [
          { orderable: false, targets: 'nosort' },
        ],
        order: [],
      });
    }

    document.addEventListener('turbo:before-cache', () => {
      const table = $('table#pocSkuLists');

      if (!table.length) { return; }

      const tableConstant = table.DataTable();
      tableConstant.destroy();
    });
  }

  initialDataTablesPocInhouse() {
    const table = 'table#pocInhouseTable';

    if (!$(table).length) { return; }

    if (!$.fn.DataTable.isDataTable(table)) {
      $(table).dataTable({
        columnDefs: [
          { orderable: false, targets: 'nosort' },
        ],
        order: [],
      });
    }

    document.addEventListener('turbo:before-cache', () => {
      const table = $('table#pocInhouseTable');

      if (!table.length) { return; }

      const tableConstant = table.DataTable();
      tableConstant.destroy();
    });
  }
}
