import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="item"
export default class extends Controller {
  connect() { }

  toggleSelectAllItem(e) {
    $("input[type=checkbox][name='item']").each((index, item) => {
      if (!item.hasAttribute('disabled')) {
        if (e.target.checked) {
            $(`#item-${item.value}`).val('true');
          } else {
            $(`#item-${item.value}`).val('false');
          }
          $(`input[type=checkbox][name='item'][value=${item.value}]`).prop(
            'checked',
            e.target.checked,
        );
      }
    });
  }

  checkBoxSeleced(e) {
    $(`#item-${e.target.value}`).prop('checked', e.target.checked);
    if (e.target.checked) {
      $(`#item-${e.target.value}`).val('true');
    } else {
      $(`#item-${e.target.value}`).val('false');
    }
  }
}
