import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['divisionSelect'];

  change(event) {
    const leader = event.target.selectedOptions[0].value;
    const target = this.divisionSelectTarget.id;

    if (+leader < 1) { return; }

    get(`/leaders/${leader}/divisions/divisions?target=${target}`, {
      responseKind: 'turbo-stream',
    });
  }
}
