import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = [
    'addPackingListBtn', 'shippingsItemDetailContainer', 'shippingsAttributes',
    'shippingSelect', 'shippingLabelsContainer', 'labelsContainer',
    'shippingsItemDetailsBody', 'selectedShippingInput', 'packingListItemsContainer',
  ];

  initialize() {
    this.initialDataTables();
  }

  customerChange(e) {
    const customerId = e.target.selectedOptions[0].value;

    if (+customerId < 1) {
      this.addPackingListBtnTarget.disabled = true;
      return;
    }

    get(`/customers/${customerId}/shipping_options`, {
      responseKind: 'turbo-stream',
    }).then(() => {
      this.addPackingListBtnTarget.disabled = false;
    }).catch(() => {
      this.addPackingListBtnTarget.disabled = true;
    });
  }

  addShipping() {
    const shippingId = this.shippingSelectTarget.value;
    const isExist = !!$(`#shipping_${shippingId}`).length;

    if (+shippingId < 1 || isExist) { return; }

    const data = JSON.parse($(this.selectedShippingInputTarget).val());
    const $shippingsAttributes = $(this.shippingsAttributesTarget);
    const $labelsContainer = $(this.labelsContainerTarget);

    $shippingsAttributes.append(this.buildShippingInputs(data));
    $labelsContainer.append(this.buildShippingLabel(data));
    this.buildPackingListItems(data);

    this.shippingLabelsContainerTarget.classList.remove('d-none');
    this.packingListItemsContainerTarget.classList.remove('d-none');
  }

  shippingChange(e) {
    const shippingId = e.target.selectedOptions[0].value;

    if (+shippingId < 1) {
      this.hiddenShippingsItemDetailContainer();
      return;
    }

    get(`/shippings/${shippingId}/shippings_item_details`, {
      responseKind: 'turbo-stream',
    }).catch(() => {
      this.hiddenShippingsItemDetailContainer();
    });
  }

  deleteShipping(e) {
    const shippingId = $(e.target).data('shipping-id');
    const table = $('table#packingListItemsTable').DataTable();

    $(`#shipping_${shippingId}`).remove();
    $(`#shipping-${shippingId}-label`).remove();
    table.rows(`.packing-list-${shippingId}`).remove().draw();

    const shippingCount = $(this.shippingsAttributesTarget).find('div').length;

    if (shippingCount == 0) {
      this.shippingLabelsContainerTarget.classList.add('d-none');
      this.packingListItemsContainerTarget.classList.add('d-none');
    }
  }

  openPackingListModal() {
    this.shippingSelectTarget.value = '';
    this.shippingSelectTarget.dispatchEvent(new Event('change'));
  }

  buildShippingInputs(shipping) {
    return $('<div>').attr({ id: `shipping_${shipping.id}` })
      .append($('<input>').attr({
        type: 'hidden',
        name: 'shipment[shipping_ids][]',
        value: shipping.id,
      }));
  }

  buildShippingLabel(shipping) {
    return $('<div>').attr({ id: `shipping-${shipping.id}-label`, class: 'shipping-label' })
      .append($('<label>').html(shipping.name))
      .append($('<button>').attr({
        type: 'button',
        class: 'btn-close',
        'data-action': 'click->shipment#deleteShipping',
        'data-shipping-id': shipping.id,
      }));
  }

  buildPackingListItems(shipping) {
    const table = $('table#packingListItemsTable').DataTable();
    const $shippingsItemDetailsBody = $(this.shippingsItemDetailsBodyTarget);

    $shippingsItemDetailsBody.find('tr').each((i, elem) => {
      const $tr = $(elem).clone().attr({ class: `packing-list-${shipping.id}` }).prepend($('<td>').html(shipping.name));
      table.row.add($tr).draw();
    });
  }

  hiddenShippingsItemDetailContainer() {
    this.shippingsItemDetailContainerTarget.classList.add('d-none');
  }

  initialDataTables() {
    const table = $('table#packingListItemsTable');

    if (!table.length) { return; }

    table.dataTable({
      order: [],
    });

    document.addEventListener('turbo:before-cache', () => {
      const table = $('table#packingListItemsTable');

      if (!table.length) { return; }

      const tableConstant = table.DataTable();
      tableConstant.destroy();
    });
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }

  openReturnShipmentStatusModal() {
    const message = document.getElementById('shipmentModalMessage');
    const shipment = document.getElementById('shipment_id');
    if (message && shipment) {
      message.style = 'white-space: pre-line';
      message.textContent = `This process will affect status of POC and Packing list. \n Do you really want to return status for this shipment #${shipment.value} to Prepare shipment?`;
      this.myModal($('#returnShipmentStatusModal')).show();
    }
  }
}
