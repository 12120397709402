import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['materialType', 'cost', 'selectMaterial', 'qty'];

  connect() {
    const material_type = this.selectMaterialTarget.selectedOptions[0].getAttribute('material_type');
    const cost = this.selectMaterialTarget.selectedOptions[0].getAttribute('cost');
    const material_id = this.selectMaterialTarget.selectedOptions[0].getAttribute('value');

    this.materialType(material_type);
    this.applyCost(cost);
    this.requireQty(material_id);
    this.initialSelect2(this.selectMaterialTarget);
  }

  change(event) {
    const material_type = event.target.selectedOptions[0].getAttribute('material_type');
    const cost = event.target.selectedOptions[0].getAttribute('cost');
    const material_id = event.target.selectedOptions[0].getAttribute('value');

    this.materialType(material_type);
    this.applyCost(cost);
    this.requireQty(material_id);
  }

  materialType(material_type) {
    this.materialTypeTarget.value = material_type;
  }

  applyCost(cost) {
    this.costTarget.value = (Math.round(Number(cost) * 100) / 100).toFixed(2);
  }

  requireQty(material_id) {
    if (material_id == '') {
      this.qtyTarget.removeAttribute('required');
    } else {
      this.qtyTarget.setAttribute('required', '');
    }
  }

  onSelect2Change(e) {
    const select = e.currentTarget;
    select.dispatchEvent(new Event('change'));
  }

  preventSelectDuplicateMaterial(e) {
    const $elem = $(e.params.args.data.element);
    const materialId = $elem.val();
    const existIds = $("select[name^='product[product_materials_attributes]']")
      .map((i, elem) => $(elem).val())
      .get();

    if (existIds.includes(materialId)) {
      e.preventDefault();
    }
  }

  initialSelect2(select) {
    if (!select.length) {
      return;
    }
    if ($(select).data('select2')) {
      return;
    }

    $(select)
      .select2()
      .on('select2:selecting', this.preventSelectDuplicateMaterial)
      .on('select2:select', this.onSelect2Change);
  }
}
